<template>
        <section>
            <v-parallax :src="require('../../../assets/Zamu 8.jpg')" height="600" class="home-parallax cross-border" align-center justify-center  :class="{'mobile-layout' :is_screen_small}">
                <v-layout>
                    <v-row no-gutters>
                        <v-col lg="6" md="6" sm="12" xs="12">
                            <v-card height="100%" flat tile xs12 sm12  width="100%" column align="left" justify="left"  class="white--text parallax-text-holder transparent">
                                <v-card-text class="white--text subheading mb-3 text-xs-center parallax-text parralax-left">
                                  <span class="zamu-heading happiness-text happiness-white">Our Unique </span>
                                  <span class="zamu-heading happiness-text mb-3 happiness-orange">Features </span>

                                  <span class="image-text mb-8 happiness-white">This digital payments platform is created to solve payment-processing problems that enable International Money Transfer Companies, Corporates SMEs and Merchants to make online and offline payments.</span>
                            
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col lg="6" md="6" sm="12" xs="12" class="hidden-sm-and-down">
                            <v-card flat tile height="100%"  width="70%" column align="right" justify="right"  class="white--text parallax-text-holder transparent">
                                <v-card-text class="white--text subheading text-xs-center parallax-text parallax-image" style="font-weight: 900; text-shadow: 2px 2px #000000;">
                                    <v-card tile flat class="image-holder transparent">
                                        <img :src="`${s3Path}purpleBlur.png`" height="200" class="">
                                        <img :src="`${s3Path}purpleRabbit.png`"  height="200" width="200" class="rabbit-img">
                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-parallax>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name:'Slider',
    mixins: [colorMixin, coreMixin],
    computed: {
        is_screen_small() {
        return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly
    },
    }

}
</script>

<style>
.cross-border  .v-parallax__content {
    background-image:
    linear-gradient(to right,  rgb(68 36 124), rgba(245, 246, 252, 0.52)),
    url('../../../assets/Zamu 8.jpg');
    background-size: cover;

    }

</style>